/* eslint-disable prettier/prettier */
import { User } from '../types/users';

const apiRoot = process.env.REACT_APP_API_URL;
class AuthApi {
  async login({
    email,
    password,
  }): Promise<{ user?: User; token?: string; success: boolean; data?: boolean; message?: string }> {
    try {
      const res = await fetch(`${apiRoot}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const { user, token, message } = await res.json();
      if (token && (user.role === 'super-admin' || user.role === 'admin')) {
        return {
          user,
          token,
          success: true,
        };
      }
      return {
        success: false,
        message:
          user?.role === 'client' || user?.role === 'staff'
            ? 'Login failed, please check your account and password again'
            : message,
      };
    } catch (err) {
      console.error('[Auth Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }

  async logout(): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/auth/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const { data } = await res.json();
      if (data) {
        return {
          success: true,
        };
      }
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async me(): Promise<{ user?: User; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/auth/current-user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const { data: user } = await res.json();
      if (user && (user.role === 'super-admin' || user.role === 'admin')) {
        return {
          user,
          success: true,
        };
      }
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const authApi = new AuthApi();
