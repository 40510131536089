/* eslint-disable prettier/prettier */
import axios from 'axios';
import { isNil, omitBy, get } from 'lodash';

import { Sale } from '../types/sale';
import { API_URL } from './api';

class SaleApi {
  async getList(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ data?: Sale[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const apiResponse = await axios.get(`${API_URL.SALE}?${query}`);
      const data = get(apiResponse, ['data', 'data'], []);
      const total = apiResponse?.data?.paging?.total;
      return { data, total, success: true };
    } catch (err) {
      console.error('[Sale Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getById({ id }: { id: string }): Promise<{ data?: Sale; success: boolean }> {
    try {
      const apiResponse = await axios.get(`${API_URL.SALE}/${id}`);
      if (apiResponse.status === 200) {
        return {
          data: apiResponse.data,
          success: true,
        };
      }
    } catch (err) {
      console.error('[SALE Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteCateApi(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const apiResponse = await axios.delete(`${API_URL.SALE}/${id}`);
      if (apiResponse.status === 200) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[SALE Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async updateApi(id: string, data: Sale): Promise<{ data?: Sale; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${API_URL.SALE}/${id}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Sale Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createApi(data: Sale): Promise<{ data?: Sale; success: boolean }> {
    try {
      const apiResponse = await axios.post(`${API_URL.SALE}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[SALE Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const saleApi = new SaleApi();
