/* eslint-disable prettier/prettier */
import axios from 'axios';
import { isNil, omitBy, get } from 'lodash';

import { Brand } from 'src/types/brand';
import { API_URL } from './api';

class BrandApi {
  async getList(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit?: number;
    offset?: number;
  }): Promise<{ data?: Brand[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const apiResponse = await axios.get(`${API_URL.BRAND}/list?${query}`);

      const data = get(apiResponse, ['data', 'data'], []);
      const total = apiResponse?.data?.paging?.total;
      return { data, total, success: true };
    } catch (err) {
      console.error('[Brand Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getById({ id }: { id: string }): Promise<{ data?: Brand; success: boolean }> {
    try {
      const apiResponse =  await axios.get(`${API_URL.BRAND}/${id}`)
      if (apiResponse.status === 200) {
        return {
          data: apiResponse.data,
          success: true,
        };
      }
    } catch (err) {
      console.error('[Brand Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteBrandApi(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const apiResponse = await axios.delete(`${API_URL.BRAND}/${id}`);
      if (apiResponse.status === 200) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[Brand Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async updateBrandApi(id: string, data: Brand): Promise<{ data?: Brand; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${API_URL.BRAND}/${id}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Brand Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createBrandApi(data: Brand): Promise<{ data?: Brand; success: boolean }> {
    try {
      const apiResponse = await axios.post(`${API_URL.BRAND}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Brand Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const brandApi = new BrandApi();
