import { isNil, omitBy } from 'lodash';
import { User } from '../types/users';
import { IOrder } from '../types/order';
import { IAddress } from '../types/address';
import { CreatingKpi, IKpiType, IKpiTypeWithOrders } from 'src/types/kpi';

const apiRoot = process.env.REACT_APP_API_URL;
class UserApi {
  async getUsers(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ users?: User[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const res = await fetch(`${apiRoot}/api/users?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
        // body: JSON.stringify({
        //   ...filter,
        // }),
      });
      const apiResponse = await res.json();
      const { users, total } = apiResponse?.data?.attributes;
      return { users, total, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getUser({ id }: { id: string }): Promise<{ user?: User; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const user = apiResponse?.data?.attributes?.user;
      if (user) {
        return {
          user,
          success: true,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteUser(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      if (success) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async superDeleteUser(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/super-delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      if (success) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async approveUser(id: string): Promise<{ user?: User; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/approve/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
      });
      const apiResponse = await res.json();
      const { user } = apiResponse?.data?.attributes;
      if (user) {
        return {
          success: true,
          user,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async updateUser(id: string, user: User): Promise<{ user?: User; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
        body: JSON.stringify(user),
      });
      const apiResponse = await res.json();
      const { user: newUser } = apiResponse?.data?.attributes;
      if (user) {
        return {
          success: true,
          user: newUser,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createUser(user: User): Promise<{ user?: User; success: boolean; message?: string }> {
    try {
      const res = await fetch(`${apiRoot}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // include, *same-origin, omit
        body: JSON.stringify(user),
      });
      const apiResponse = await res.json();
      const newUser = apiResponse?.data?.attributes?.user;
      if (newUser) {
        return {
          success: true,
          user: newUser,
        };
      }
      if (apiResponse.statusCode === 400) {
        return {
          success: false,
          message: apiResponse.message,
        };
      }
    } catch (err) {
      console.error('[User Api]: ', err);
      return {
        success: false,
        message: err.message,
      };
    }
    return {
      success: false,
    };
  }

  async getOrders(
    userId: string,
    filter?: {
      status?: string;
      orderBy?: string;
      orderDirection?: 'asc' | 'desc';
      limit: number;
      offset: number;
    },
  ): Promise<{ orders?: IOrder[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const res = await fetch(`${apiRoot}/api/users/${userId}/orders?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const { orders, total } = apiResponse?.data?.attributes;
      return { orders, total, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getAddress(userId: string): Promise<{ addresses?: IAddress[]; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/address`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const addressesRaw = apiResponse?.data;
      const addresses = addressesRaw.map((address) => ({ id: address.id, ...address.attributes }));
      return { addresses, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getKpis(userId: string): Promise<{ kpis?: IKpiType[]; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpis`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const kpisRaw = apiResponse?.data;
      const kpis = kpisRaw.map((kpi) => ({ id: kpi.id, ...kpi.attributes }));
      return { kpis, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getKpi(userId: string, month: number, year: number): Promise<{ kpi?: IKpiTypeWithOrders; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpi?year=${year}&month=${month}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const kpi = apiResponse?.data;
      return { kpi: { id: kpi.id, ...kpi.attributes }, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createUserKpi(
    userId: string,
    creatingKpi: CreatingKpi,
  ): Promise<{ kpi?: IKpiTypeWithOrders; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(creatingKpi),
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const kpi = apiResponse?.data;
      return { kpi: { id: kpi.id, ...kpi.attributes }, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async updateUserKpi(
    userId: string,
    kpiId: string,
    creatingKpi: CreatingKpi,
  ): Promise<{ kpi?: IKpiTypeWithOrders; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpi/${kpiId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(creatingKpi),
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const kpi = apiResponse?.data;
      return { kpi: { id: kpi.id, ...kpi.attributes }, success: true };
    } catch (err) {
      console.error('[User Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteKpiId(kpiId: string): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/kpis/${kpiId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      return { success: true };
    } catch (err) {
      console.error('[KPI Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async activeKpi(kpiId: string, userId: string): Promise<{ success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}/api/users/${userId}/kpi/${kpiId}/active`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      await res.json();
      return { success: true };
    } catch (err) {
      console.error('[KPI Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const userApi = new UserApi();
