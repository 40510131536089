/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-undef */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-restricted-globals */
/* eslint-disable promise/no-nesting */
/* eslint-disable arrow-body-style */
/* eslint-disable promise/always-return */
/* eslint-disable prettier/prettier */
// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

export let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

// Get the FCM token
export const getToken = () =>
  messaging
    ?.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
    })
    .then((token) => {
      console.log('token', token);
      if (token) {
        localStorage.setItem('fcmToken', token);
        // // Handle incoming messages
        messaging.onMessage((payload) => {
          console.log('Message received in get token:', payload);
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            image: payload.notification.image,
            data: payload.data,
            tag: `noti-${new Date().getTime()}`,
          };

          self.addEventListener('notificationclick', function listener(event) {
            const url = payload?.data?.url;
            event.notification.close(); // Android needs explicit close.
            event.waitUntil(
              clients.matchAll({ type: 'window' }).then((windowClients) => {
                // Check if there is already a window/tab open with the target URL
                for (let i = 0; i < windowClients.length; i++) {
                  const client = windowClients[i];
                  // If so, just focus it.
                  if (client.url === url && 'focus' in client) {
                    return client.focus();
                  }
                }
                // If not, then open the target URL in a new window/tab.
                if (clients.openWindow && url) {
                  return clients.openWindow(url);
                }
                return null;
              }),
            );
          });
          self?.registration?.showNotification(notificationTitle, notificationOptions);
        });

        return token;
      }
      return '';
    });


export const requestNotificationPermission = async () => {
  try {
    const token = await messaging?.requestPermission();
    console.log('Notification permission granted', token);
    const fcmToken = await getToken();
    return fcmToken;
  } catch (error) {
    console.error('Error requesting notification permission:', error);
    return '';
  }
};
