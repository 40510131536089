import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppState = {
  requestCount: number;
};

const initialState: AppState = {
  requestCount: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRequestCount(state, action: PayloadAction<number>) {
      state.requestCount += action.payload;
    },
  },
});

export const { setRequestCount } = appSlice.actions;

export const { reducer } = appSlice;

export default appSlice;
