import { Button, DialogActions, DialogContent, DialogTitle, Dialog as MuiDialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { setDialog } from 'src/slices/dialog';
import { useDispatch, useSelector } from 'src/store';

export type ConfirmDialogProps = {
  open: boolean;
  title?: string;
  content?: string;
  confirmLabel?: JSX.Element;
  cancelLabel?: JSX.Element;
  confirmButton?: JSX.Element;
  cancelButton?: JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};
export default function ConfirmDialog() {
  const { t } = useTranslation();
  const dialogProps = useSelector((state) => state.dialog.dialog);
  const {
    open,
    title,
    content,
    onCancel,
    onConfirm,
    confirmLabel,
    maxWidth,
    cancelLabel,
    confirmButton,
    cancelButton,
  } = dialogProps || {};
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
      }),
    );
  };
  const handleCancel = () => {
    onCancel?.();
    handleClose();
  };

  const handleConfirm = () => {
    onConfirm?.();
    handleClose();
  };
  return (
    <MuiDialog open={open} onClose={handleClose} maxWidth={maxWidth || 'sm'} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        {cancelButton || <Button onClick={handleCancel}>{cancelLabel || t('button-cancel')}</Button>}
        {confirmButton || (
          <Button onClick={handleConfirm} color="primary" variant="contained">
            {confirmLabel || t('button-confirm')}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
}
