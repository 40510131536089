import { Box, Button, Drawer, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALES } from 'src/constants';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
  locale: settings.locale,
});

const SettingsDrawer: FC = () => {
  const { settings, saveSettings, open, setOpen } = useSettings();
  const [values, setValues] = useState(getValues(settings));
  const { t } = useTranslation();

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            width: 320,
          },
        }}
      >
        <Typography color="textPrimary" variant="h6">
          {t('setting')}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label={t('locale')}
            name="locale"
            onChange={(event): void => handleChange('locale', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.locale}
            variant="outlined"
          >
            {LOCALES.map((locale) => (
              <option key={locale.value} value={locale.value}>
                {locale.label}
              </option>
            ))}
          </TextField>
        </Box>

        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label={t('theme')}
            name="theme"
            onChange={(event): void => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option key={theme} value={theme}>
                {t(theme.toLowerCase())}
              </option>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.direction === 'rtl'}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
              />
            }
            label={
              <div>
                {t('rtl')}
                <Typography color="textSecondary" component="p" variant="caption">
                  {t('change-text-direction')}
                </Typography>
              </div>
            }
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event): void => handleChange('responsiveFontSizes', event.target.checked)}
              />
            }
            label={
              <div>
                {t('responsive-font-size')}
                <Typography color="textSecondary" component="p" variant="caption">
                  {t('adjust-font')}
                </Typography>
              </div>
            }
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.compact}
                color="primary"
                edge="start"
                name="compact"
                onChange={(event): void => handleChange('compact', event.target.checked)}
              />
            }
            label={
              <div>
                {t('compact')}
                <Typography color="textSecondary" component="p" variant="caption">
                  {t('fixed-width')}
                </Typography>
              </div>
            }
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.roundedCorners}
                color="primary"
                edge="start"
                name="roundedCorners"
                onChange={(event): void => handleChange('roundedCorners', event.target.checked)}
              />
            }
            label={
              <div>
                {t('rounded-corner')}
                <Typography color="textSecondary" component="p" variant="caption">
                  {t('increase-border-radius')}
                </Typography>
              </div>
            }
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button color="primary" fullWidth onClick={handleSave} variant="contained">
            {t('save-setting')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
