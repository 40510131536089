import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { setRequestCount } from 'src/slices/app';
import toast from 'react-hot-toast';
import get from 'lodash.get';

// import { enqueueAppSnackbar } from './enqueueAppSnackbar';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const onRequest =
  (store: any) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { isNotLoading, ...restConfig } = config;
    if (!isNotLoading) {
      store.dispatch(setRequestCount(1));
    }

    return restConfig;
  };

const onResponse =
  (store: any) =>
  (response: AxiosResponse): AxiosResponse => {
    const {
      config: { isNotLoading },
    } = response;
    if (!isNotLoading) {
      store.dispatch(setRequestCount(-1));
    }
    if (![200, 201].includes(response.status) || !response.data) {
      toast.error(response.data?.message);
    }
    return response;
  };

const onResponseError =
  (store: any) =>
  (error: AxiosError | Error): Promise<AxiosError> => {
    if (axios.isAxiosError(error)) {
      const { isNotLoading } = error.config as AxiosRequestConfig;
      const message = get(error, ['response', 'data', 'message']);

      if (!isNotLoading) {
        store.dispatch(setRequestCount(-1));
      }

      let displayError;
      const unexpectedMessage = get(message, 'message');
      if (typeof message === 'string') {
        displayError = message;
      }
      if (typeof message !== 'string') {
        if (unexpectedMessage) {
          displayError = unexpectedMessage;
        } else {
          displayError = error.message;
        }
      }
      displayError && toast.error(displayError);
    }
    return Promise.reject(error);
  };

export function setupInterceptorsTo(axiosInstance: AxiosInstance, store: any): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest(store));
  axiosInstance.interceptors.response.use(onResponse(store), onResponseError(store));
  return axiosInstance;
}
