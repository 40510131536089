import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang/en/translation.json';
import vi from './lang/vi/translation.json';

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
      ...en,
    },
  },
  vi: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
      ...vi,
    },
  },
  cs: {
    translation: {},
  },
};

void i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
