import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { subCateAPI } from 'src/api/subCateAPI';
import { SubCateCUFormValues } from 'src/components/sub-category/CUForm';
import { INIT_PAGINATION } from 'src/constants';
import { SubCategoryFilterValues } from 'src/pages/sub-category/list/FilterBar';

import { AppThunk } from 'src/store';
import { DataPaging, IPagination } from 'src/types/data';
import { ISubCategory, ISubCategoryParams } from 'src/types/sub-category';

export type SubCateState = {
  data: DataPaging<ISubCategory[]>;
  params: ISubCategoryParams;
  filter: SubCategoryFilterValues;
};

const initialState: SubCateState = {
  data: {
    data: [],
    pagination: INIT_PAGINATION,
  },
  params: {},
  filter: {
    limit: 10,
    page: 0,
    sort: 'createdAt|desc',
    searchValue: '',
  },
};

const subCateSlice = createSlice({
  name: 'subCate',
  initialState,
  reducers: {
    setDataSubCategory(
      state,
      action: PayloadAction<{ data: DataPaging<ISubCategory[]> | null; params: ISubCategoryParams; filter: any }>,
    ) {
      state.data = action.payload.data;
      state.filter = action.payload.filter;
      state.params = action.payload.params;
    },
    updateFilter(state: SubCateState, action: PayloadAction<Record<string, string>>): void {
      const filter = action.payload;
      state.filter = {
        ...state.filter,
        ...filter,
      };
    },
  },
});
export const {
  reducer,
  actions: { setDataSubCategory },
} = subCateSlice;

export default subCateSlice;

export const updateFilter =
  (filter): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(subCateSlice.actions.updateFilter(filter));
  };

export const getListSubCategory =
  ({
    filter,
    pagination,
    params,
  }: {
    filter?: any;
    pagination: Omit<IPagination, 'count'>;
    params: ISubCategoryParams;
  }): AppThunk =>
  async (dispatch): Promise<void> => {
    const data = await subCateAPI.getList(params, pagination);
    if (data) {
      dispatch(
        setDataSubCategory({
          data: {
            ...data,
            pagination: {
              ...pagination,
              count: data.paging.total,
            },
          },
          filter,
          params,
        }),
      );
    }
  };

export const deleteSubCategoryById =
  (id: string, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await subCateAPI.deleteById(id);
    if (data?.success) {
      callback?.();
    }
  };
export const createSubCategory =
  (params: SubCateCUFormValues, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await subCateAPI.postSubCategory(params);
    if (data?.success) {
      callback?.();
    }
  };

export const updateSubCategory =
  (id: string, params: SubCateCUFormValues, callback?: () => void): AppThunk =>
  async (): Promise<void> => {
    const data = await subCateAPI.patchSubCategory(id, params);
    if (data?.success) {
      callback?.();
    }
  };
