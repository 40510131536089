/* eslint-disable prettier/prettier */
export const API_URL = {
  MEGA_CATEGORY: '/api/mega-category',
  SUB_CATEGORY: '/api/sub-category',
  CATEGORY: '/api/category',
  FILES: '/api/files',
  PRODUCT: '/api/product',
  ADVERTISER: '/api/advertiser',
  TAG: '/api/tag',
  SALE: '/api/sale',
  RECIPE: '/api/recipe',
  ORDER: '/api/orders',
  POSTCODE: '/api/postcode',
  OVERVIEW: '/api/overview',
  REPORT: '/api/report',
  STORE: '/api/store',
  SEARCH: '/api/search',
  BRAND: '/api/brand',
  BRAND_CATEGORY: '/api/brand-category',
  CATALOG: '/api/catalog'
};
