/* eslint-disable prettier/prettier */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { postcodeApi } from 'src/api/postcode';
import type { AppThunk } from 'src/store';
import type { Filter } from 'src/types/megaCategory';
import type { Postcode } from 'src/types/postcode';
import objFromArray from 'src/utils/objFromArray';

interface PostcodeState {
  postcode: {
    byId: Record<string, Postcode>;
    allIds: string[];
  };
  status: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  targetId: string;
  total: number;
  filter: Filter;
  pageKey?: Record<string, unknown>;
}

const initialState: PostcodeState = {
  postcode: {
    byId: {},
    allIds: [],
  },
  filter: {
    limit: 10,
    page: 0,
    sort: 'startCode|asc',
    searchValue: '',
  },
  status: 'idle',
  total: 0,
  targetId: '',
};

const slice = createSlice({
  name: 'postcode',
  initialState,
  reducers: {
    loading(state: PostcodeState, action: PayloadAction): void {
      state.status = 'loading';
    },
    error(state: PostcodeState, action: PayloadAction): void {
      state.status = 'error';
    },
    setPageKey(state: PostcodeState, action: PayloadAction<Record<string, unknown>>): void {
      state.pageKey = action.payload;
    },
    getPostcodeList(state: PostcodeState, action: PayloadAction<{ data: Postcode[]; total: number }>): void {
      const { data, total } = action.payload;
      state.status = 'success';
      state.total = total;
      state.postcode.byId = objFromArray(data);
      state.postcode.allIds = Object.keys(state.postcode.byId);
    },
    getByPostcodeId(state: PostcodeState, action: PayloadAction<Postcode>): void {
      const data = action.payload;
      state.status = 'success';
      state.targetId = data.id;
      state.postcode.byId[data.id] = data;
    },
    updatePostcode(state: PostcodeState, action: PayloadAction<{ data: Partial<Postcode>; id: string }>): void {
      const { data, id } = action.payload;
      const existingRecord = state.postcode.byId[id];
      if (existingRecord) {
        state.postcode.byId[id] = {
          ...existingRecord,
          ...data,
        };
      }
    },
    updateFilter(state: PostcodeState, action: PayloadAction<Record<string, string>>): void {
      const filter = action.payload;
      state.filter = {
        ...state.filter,
        ...filter,
      };
    },
  },
});

export const { reducer } = slice;

export const updateFilter =
  (filter): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateFilter(filter));
  };

export const getList =
  (filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.loading());
    const { data, total, success } = await postcodeApi.getList(filter);
    if (success) {
      dispatch(slice.actions.getPostcodeList({ data, total }));
    } else {
      dispatch(slice.actions.error());
    }
  };

export const getById =
  ({ id }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { data, success } = await postcodeApi.getById({ id });
    if (!success) {
      dispatch(slice.actions.error());
    } else {
      dispatch(slice.actions.getByPostcodeId(data));
    }
  };

export const update =
  ({ data, id, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await postcodeApi.updateApi(id, data);
    if (success) {
      onComplete({ success });
    } else {
      onError('Cannot update postcode');
    }
  };

export default slice;
