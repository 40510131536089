/* eslint-disable prettier/prettier */
import { isNil, omitBy, get } from 'lodash';
import axios from 'axios';

import { Advertiser } from '../types/advertiser';
import { API_URL } from './api';

class AdvertiserApi {
  async getList(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit?: number;
    offset?: number;
  }): Promise<{ data?: Advertiser[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const apiResponse = await axios.get(`${API_URL.ADVERTISER}/list?${query}`);
      const data = get(apiResponse, ['data', 'data'], []);
      const total = apiResponse?.data?.paging?.total;
      return { data, total, success: true };
    } catch (err) {
      console.error('[Advertiser Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getById({ id }: { id: string }): Promise<{ data?: Advertiser; success: boolean }> {
    try {
      const apiResponse = await axios.get(`${API_URL.ADVERTISER}/${id}`);
      if (apiResponse.status === 200) {
        return {
          data: apiResponse.data,
          success: true,
        };
      }
    } catch (err) {
      console.error('[Advertiser Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteApi(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const apiResponse = await axios.delete(`${API_URL.ADVERTISER}/${id}`);
      if (apiResponse.data) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[Advertiser Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async updateApi(id: string, data: Advertiser): Promise<{ data?: Advertiser; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${API_URL.ADVERTISER}/${id}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Advertiser Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createApi(data: Advertiser): Promise<{ data?: Advertiser; success: boolean }> {
    try {
      const apiResponse = await axios.post(`${API_URL.ADVERTISER}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Advertiser Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const adsApi = new AdvertiserApi();
