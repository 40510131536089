/* eslint-disable prettier/prettier */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { tagApi } from 'src/api/tagApi';
import type { AppThunk } from '../store';
import type { Filter } from '../types/megaCategory';
import type { Tag } from '../types/tag';
import objFromArray from '../utils/objFromArray';

interface TagState {
  tag: {
    byId: Record<string, Tag>;
    allIds: string[];
  };
  status: 'idle' | 'loading' | 'success' | 'error' | 'updating';
  targetId: string;
  total: number;
  filter: Filter;
  pageKey?: Record<string, unknown>;
}

const initialState: TagState = {
  tag: {
    byId: {},
    allIds: [],
  },
  filter: {
    limit: 10,
    page: 0,
    sort: 'createdAt|desc',
    searchValue: '',
  },
  status: 'idle',
  total: 0,
  targetId: '',
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    loading(state: TagState, action: PayloadAction): void {
      state.status = 'loading';
    },
    error(state: TagState, action: PayloadAction): void {
      state.status = 'error';
    },
    setPageKey(state: TagState, action: PayloadAction<Record<string, unknown>>): void {
      state.pageKey = action.payload;
    },
    deleteTag(state: TagState, action: PayloadAction<{ id: string }>): void {
      delete state.tag.byId[action.payload.id];
      state.tag.allIds = Object.keys(state.tag.byId);
    },
    getTagList(state: TagState, action: PayloadAction<{ data: Tag[]; total: number }>): void {
      const { data, total } = action.payload;
      state.status = 'success';
      state.total = total;
      state.tag.byId = objFromArray(data);
      state.tag.allIds = Object.keys(state.tag.byId);
    },
    getByTagId(state: TagState, action: PayloadAction<Tag>): void {
      const data = action.payload;
      state.status = 'success';
      state.targetId = data.id;
      state.tag.byId[data.id] = data;
    },
    updateTag(state: TagState, action: PayloadAction<{ data: Partial<Tag>; id: string }>): void {
      const { data, id } = action.payload;
      const existingRecord = state.tag.byId[id];
      if (existingRecord) {
        state.tag.byId[id] = {
          ...existingRecord,
          ...data,
        };
      }
    },
    updateFilter(state: TagState, action: PayloadAction<Record<string, string>>): void {
      const filter = action.payload;
      state.filter = {
        ...state.filter,
        ...filter,
      };
    },
    createTag(state: TagState, action: PayloadAction<{ data: Tag }>): void {
      const { data } = action.payload;
      state.total += 1;
      state.tag.byId[data.id] = data;
      state.tag.allIds.push(data.id);
    },
  },
});

export const { reducer } = slice;

export const deleteTag =
  ({ id, onSuccess, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      await tagApi.deleteTagApi(id);
      dispatch(slice.actions.deleteTag({ id }));
      onSuccess();
    } catch (err) {
      onError(err);
    }
  };

export const updateFilter =
  (filter): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.updateFilter(filter));
  };

export const getList =
  (filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.loading());
    const { data, total, success } = await tagApi.getList(filter);
    if (success) {
      dispatch(slice.actions.getTagList({ data, total }));
    } else {
      dispatch(slice.actions.error());
    }
  };

export const getById =
  ({ id }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { data, success } = await tagApi.getById({ id });
    if (!success) {
      dispatch(slice.actions.error());
    } else {
      dispatch(slice.actions.getByTagId(data));
    }
  };

export const update =
  ({ data, id, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success } = await tagApi.updateTagApi(id, data);
    if (success) {
      onComplete({ success });
      dispatch(slice.actions.updateTag({ data, id }));
      dispatch(slice.actions.updateFilter({ 
        sort: 'modifiedAt|desc',
      }));
    } else {
      onError('Cannot update tag');
    }
  };

export const create =
  ({ input, onComplete, onError }): AppThunk =>
  async (dispatch): Promise<void> => {
    const { success, data } = await tagApi.createTagApi(input);
    if (success) {
      onComplete({ success, data });
      dispatch(slice.actions.createTag({ data }));
    } else {
      onError('Cannot create tag');
    }
  };

export default slice;
