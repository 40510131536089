import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfirmDialogProps } from 'src/components/ConfirmDialog';

export type DialogState = { dialog: ConfirmDialogProps };

const initialState: DialogState = {
  dialog: {
    open: false,
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialog(state, action: PayloadAction<ConfirmDialogProps>) {
      state.dialog = action.payload;
    },
  },
});

export const { setDialog } = dialogSlice.actions;

export const { reducer } = dialogSlice;

export default dialogSlice;
