/* eslint-disable prettier/prettier */
import axios from 'axios';
import { isNil, omitBy, get } from 'lodash';

import { Recipe } from '../types/recipe';
import { API_URL } from './api';

class RecipeApi {
  async getList(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit: number;
    offset: number;
  }): Promise<{ data?: Recipe[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const apiResponse = await axios.get(`${API_URL.RECIPE}?${query}`);
      const data = get(apiResponse, ['data', 'data'], []);
      const total = apiResponse?.data?.paging?.total;
      return { data, total, success: true };
    } catch (err) {
      console.error('[Recipe Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getById({ id }: { id: string }): Promise<{ data?: Recipe; success: boolean }> {
    try {
      const apiResponse = await axios.get(`${API_URL.RECIPE}/${id}`);
      if (apiResponse.status === 200) {
        
        const material = typeof apiResponse?.data?.material === 'string' ? JSON.parse(apiResponse?.data?.material) : apiResponse?.data?.material;
        const process = typeof apiResponse?.data?.process === 'string' ? JSON.parse(apiResponse?.data?.process) : apiResponse?.data?.process;
        return {
          data: {
            ...apiResponse.data,
            material,
            process,
          },
          success: true,
        };
      }
    } catch (err) {
      console.error('[Recipe Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteRecipeApi(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const apiResponse = await axios.delete(`${API_URL.RECIPE}/${id}`);
      if (apiResponse.status === 200) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[Recipe Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async updateApi(id: string, data: Recipe): Promise<{ data?: Recipe; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${API_URL.RECIPE}/${id}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Recipe Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createApi(data: Recipe): Promise<{ data?: Recipe; success: boolean }> {
    try {
      const apiResponse = await axios.post(`${API_URL.RECIPE}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Recipe Api]: ', err);
    }
    return {
      success: false,
    };
  }
}

export const recipeApi = new RecipeApi();
