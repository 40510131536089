import type { Theme } from '@material-ui/core';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import ListIcon from '@material-ui/icons/List';
import Business from '@material-ui/icons/Business';
import Store from '@material-ui/icons/Store';
import Star from '@material-ui/icons/Star';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import ChartSquareBarIcon from 'src/icons/ChartSquareBar';
import ChartPieIcon from 'src/icons/ChartPie';
import ClipboardListIcon from 'src/icons/ClipboardList';
import Collection from 'src/icons/Collection';
import FolderOpenIcon from 'src/icons/FolderOpen';
import ShoppingCartIcon from 'src/icons/ShoppingCart';
import CurrencyDollar from 'src/icons/CurrencyDollar';
import BriefCase from 'src/icons/Briefcase';
import UserIcon from 'src/icons/User';
import UsersIcon from 'src/icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

export const demoSections = [
  {
    title: 'menu-general',
    clickable: true,
    items: [
      {
        title: 'menu-overview',
        path: '/dashboard',
        clickable: true,
        icon: <ChartPieIcon fontSize="small" />,
      },
      {
        title: 'menu-report',
        path: '/dashboard/report',
        clickable: true,
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: 'menu-account',
        path: '/dashboard/account',
        clickable: true,
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'menu-management',
    clickable: true,
    items: [
      {
        title: 'menu-user',
        clickable: true,
        path: '/management/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/management/customers',
          },
          {
            title: 'menu-detail',
            clickable: false,
            path: '/management/customers/:id/detail',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/management/customers/:id/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/management/customers/new',
          },
        ],
      },
      {
        title: 'menu-order',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/orders',
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/orders',
          },
          {
            title: 'menu-detail',
            clickable: false,
            path: '/orders/:orderId',
          },
        ],
      },
      {
        title: 'menu-mega-category',
        path: '/mega-category',
        icon: <ListIcon fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/mega-category',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/mega-category/:megaId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/mega-category/new',
          },
        ],
      },
      {
        title: 'menu-category',
        path: '/category',
        icon: <FormatListNumberedIcon fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/category',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/category/:cateId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/category/new',
          },
        ],
      },
      {
        title: 'menu-sub-category',
        path: '/sub-category',
        icon: <ClipboardListIcon fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/sub-category',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/sub-category/new',
          },
        ],
      },
      {
        title: 'menu-advertiser-banner',
        path: '/advertiser',
        icon: <Collection fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/advertiser',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/advertiser/:adsId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/advertiser/new',
          },
        ],
      },
      {
        title: 'menu-tag',
        path: '/tag',
        icon: <LocalOfferIcon fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/tag',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/tag/:adsId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/tag/new',
          },
        ],
      },
      {
        title: 'menu-product',
        path: '/products',
        icon: <ShoppingCartIcon fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/products',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/products/new',
          },
        ],
      },
      {
        title: 'menu-sale',
        path: '/sale',
        icon: <CurrencyDollar fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/sale',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/sale/:saleId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/sale/new',
          },
        ],
      },
      {
        title: 'menu-chef-recipe',
        path: '/recipe',
        icon: <BriefCase fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/recipe',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/recipe/:recipeId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/recipe/new',
          },
        ],
      },
      {
        title: 'menu-postcode',
        path: '/postcode',
        icon: <Business fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/postcode',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/postcode/:postcodeId/edit',
          },
        ],
      },
      {
        title: 'menu-store',
        path: '/store',
        icon: <Store fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/store',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/store/:storeId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/store/new',
          },
        ],
      },
      {
        title: 'brand',
        path: '/mega-brand',
        icon: <Star fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/brand',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/brand/:brandId/edit',
          },
          {
            title: 'menu-create',
            clickable: true,
            path: '/brand/new',
          },
        ],
      },
      {
        title: 'catalog',
        path: '/catalog',
        icon: <LibraryBooks fontSize="small" />,
        children: [
          {
            title: 'menu-list',
            clickable: true,
            path: '/catalog',
          },
          {
            title: 'menu-edit',
            clickable: false,
            path: '/catalog/:catalogId/edit',
          },
          {
            title: 'menu-create',
            clickable: false,
            path: '/catalog/new/:catalog',
          },
        ],
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    onMobileClose?.();
  }, [location.pathname, onMobileClose]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.fullName}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {demoSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default React.memo(DashboardSidebar);
