import { omitBy } from 'lodash';
import type { Order, OrderFilterType, OrderStatusEnum } from '../types/order';
import { API_URL } from './api';

const apiRoot = process.env.REACT_APP_API_URL;

class OrderApi {
  async getOrders(filter: OrderFilterType): Promise<{ orders?: Order[]; success: boolean; total?: number }> {
    try {
      const query = new URLSearchParams(omitBy(filter, (value: any) => !value)).toString();
      const res = await fetch(`${apiRoot}${API_URL.ORDER}?${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const { orders, total } = apiResponse?.data?.attributes;
      return { orders, total, success: true };
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getOrder(orderId: string): Promise<{ order?: Order; success: boolean }> {
    try {
      const res = await fetch(`${apiRoot}${API_URL.ORDER}/${orderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const { orders } = apiResponse?.data?.attributes;
      return {
        order: orders,
        success: true,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      success: false,
    };
  }

  async updateOrderStatus(
    orderId: string,
    status: OrderStatusEnum,
  ): Promise<{ success: boolean; orderId?: string; status?: OrderStatusEnum }> {
    try {
      const res = await fetch(`${apiRoot}${API_URL.ORDER}/${orderId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          status,
        }),
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      return {
        orderId,
        status,
        success,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      success: false,
    };
  }

  async deleteOrder(orderId: string): Promise<{ success: boolean; orderId?: string; status?: OrderStatusEnum }> {
    try {
      const res = await fetch(`${apiRoot}${API_URL.ORDER}/${orderId}/force`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      return {
        orderId,
        success,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      success: false,
    };
  }

  async updateOrderAdminShow(
    orderId: string,
    adminShow: boolean,
  ): Promise<{ success: boolean; orderId?: string; adminShow?: boolean }> {
    try {
      const res = await fetch(`${apiRoot}${API_URL.ORDER}/${orderId}/show`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          adminShow,
        }),
      });
      const apiResponse = await res.json();
      const { success } = apiResponse?.data?.attributes;
      return {
        orderId,
        adminShow,
        success,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      success: false,
    };
  }
}

export const orderApi = new OrderApi();
