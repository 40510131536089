/* eslint-disable prettier/prettier */
import axios from 'axios';
import { isNil, omitBy, get } from 'lodash';

import { MegaCategory } from 'src/types/megaCategory';
import { API_URL } from './api';
import { checkFile } from 'src/utils';

const apiRoot = process.env.REACT_APP_API_URL;
class MegaCategoryApi {
  async getList(filter?: {
    searchValue?: string;
    status?: string;
    orderBy?: string;
    orderDirection?: 'asc' | 'desc';
    limit?: number;
    offset?: number;
  }): Promise<{ data?: MegaCategory[]; total?: number; success: boolean }> {
    try {
      const query = new URLSearchParams(omitBy(filter, isNil)).toString();
      const apiResponse = await axios.get(`${API_URL.MEGA_CATEGORY}/list?${query}`);

      const data = get(apiResponse, ['data', 'data'], []);
      const total = apiResponse?.data?.paging?.total;
      return { data, total, success: true };
    } catch (err) {
      console.error('[Mega Category Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async getById({ id }: { id: string }): Promise<{ data?: MegaCategory; success: boolean }> {
    try {
      const apiResponse = await axios.get(`${API_URL.MEGA_CATEGORY}/${id}`);
      if (apiResponse.status === 200) {
        return {
          data: apiResponse.data,
          success: true,
        };
      }
    } catch (err) {
      console.error('[Mega Category Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async deleteMegaCateApi(id: string): Promise<{ status: 'success' | 'fail' }> {
    try {
      const apiResponse = await axios.delete(`${API_URL.MEGA_CATEGORY}/${id}`);
      if (apiResponse.status === 200) {
        return {
          status: 'success',
        };
      }
    } catch (err) {
      console.error('[Mega Category Api]: ', err);
    }
    return {
      status: 'fail',
    };
  }

  async updateMegaCate(id: string, data: MegaCategory): Promise<{ data?: MegaCategory; success: boolean }> {
    try {
      const apiResponse = await axios.patch(`${API_URL.MEGA_CATEGORY}/${id}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Mega Category Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async createMegaCateApi(data: MegaCategory): Promise<{ data?: MegaCategory; success: boolean }> {
    try {
      const apiResponse = await axios.post(`${API_URL.MEGA_CATEGORY}`, data);
      if (apiResponse.status === 200) {
        return {
          success: true,
          data: apiResponse.data,
        };
      }
    } catch (err) {
      console.error('[Mega Category Api]: ', err);
    }
    return {
      success: false,
    };
  }

  async uploadImageApi(formData: FormData, fileType?: string): Promise<{ data?: MegaCategory; success: boolean }> {
    const fileData = formData.get('file') as File;
    try {
      const fileCheck = checkFile(fileData, fileType);
      if (!fileCheck) {
        return undefined;
      }
      const res = await fetch(`${apiRoot}/api/files`, {
        method: 'POST',
        credentials: 'include', // include, *same-origin, omit
        body: formData,
      });
      const apiResponse = await res.json();
      if (apiResponse) {
        return { data: apiResponse, success: true };
      }
    } catch (err) {
      console.error('[File Api]: ', err);
    }
    return undefined;
  }
}

export const megaCateApi = new MegaCategoryApi();
