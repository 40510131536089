import axios from 'axios';
import { isNil, omitBy } from 'lodash';
import { SubCateCUFormValues } from 'src/components/sub-category/CUForm';
import { DEFAULT_PAGESIZE } from 'src/constants';
import { IPagination, ResponseDataPaging } from 'src/types/data';
import { ISubCategory, ISubCategoryParams } from 'src/types/sub-category';
import { API_URL } from './api';

class SubCategoryAPI {
  async getList(
    params?: ISubCategoryParams,
    pagination?: Omit<IPagination, 'count'>,
  ): Promise<ResponseDataPaging<ISubCategory[]> | null> {
    const query = new URLSearchParams(omitBy(params, isNil)).toString();
    const response = await axios.get(
      `${API_URL.SUB_CATEGORY}?${query}&limit=${pagination.rowsPerPage || DEFAULT_PAGESIZE}&offset=${
        pagination.rowsPerPage * pagination.page || 0
      }`,
    );
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async deleteById(id: string): Promise<{ success: boolean }> {
    const response = await axios.delete(`${API_URL.SUB_CATEGORY}/${id}`);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async postSubCategory(params: SubCateCUFormValues): Promise<{ success: boolean }> {
    const response = await axios.post(`${API_URL.SUB_CATEGORY}`, params);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }

  async patchSubCategory(id: string, params: SubCateCUFormValues): Promise<{ success: boolean }> {
    const response = await axios.patch(`${API_URL.SUB_CATEGORY}/${id}`, params);
    if (response?.status === 200) {
      return { success: true };
    }
    return { success: false };
  }
}

export const subCateAPI = new SubCategoryAPI();
