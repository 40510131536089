import { isNil } from 'lodash';
import { requestNotificationPermission } from 'src/firebase';

const apiRoot = process.env.REACT_APP_API_URL;
class FcmApi {
  async initialToken(): Promise<any> {
    try {
      let fcmToken = localStorage.getItem('fcmToken');
      
      if (!fcmToken) {
        fcmToken = await requestNotificationPermission();
        setTimeout(() => {
          void this.initialToken();
        }, 3000);
        return;
      }
      await fetch(`${apiRoot}/api/fcm?fcmToken=${isNil(fcmToken) ? '' : fcmToken}`, {
        method: 'GET',
        credentials: 'include',
      });
      return {
        success: true,
      };
    } catch (err) {
      console.error('[FCM Api]: ', err);
      return {
        success: false,
        message: err.toString(),
      };
    }
  }
}

export const fcmApi = new FcmApi();
